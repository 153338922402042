import React from "react";
import { Box, Grid } from "@mui/material";
import EmployerProgressCard from "./progress_card.jsx";
function FirstSectionProgressDetailsList() {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        spacing={{ md: 12, xs: 4 }}
      >
        {[...Array(3)].map((_, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <EmployerProgressCard key={i} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FirstSectionProgressDetailsList;
