import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  Avatar,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
//  import apiLogo40 from "../../images/api_logo.png";

import "./navigation_bar.styles.scss";
import EmployerCreateJob from "../../components/form/popup_employer_create_job";
// import { logout } from "../../redux/authSlice";
import UserPopover from "../account_popover/account_popover";
import { stringAvatar } from "../../utils/formatName";
import NavDrawer from "./nav_drawer";
import NavItemsAndLogo from "./nav_items_and_logo";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 140;

// EMPLOYER NAVIGATION ITEMS
const employerNavItems = [
  { label: "Home", url: "/employer" },
  { label: "Instruction", url: "/employer/instruction" },
  { label: "Progress", url: "/employer/progress" },
  // { label: "Contact", url: "/contact" },
];

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "green",
  // backgroundColor: "transparent",
  "&.MuiToolbar-root": {
    backgroundColor: "transparent",
  },
});

const EmployerNavBar = () => {
  const [aBar, setAppBar] = React.useState(false);
  const [openCreateJobDialog, setOpenCreateJobDialog] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openAvatar = Boolean(anchorEl);
  const avatarId = openAvatar ? "simple-popover" : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  // function handleLogout() {
  //   dispatch(logout());
  //   navigate("/");
  // }
  //custom drawer

  const navBarItemsRightMobile = (
    <>
      <Box
        sx={{
          // width: "120px",
          // border: "1px solid #ffffff",
          borderRadius: "5px",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          // backgroundColor: "yellow",
        }}
      >
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setOpenCreateJobDialog(true);
          }}
        >
          Create Job
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "120px",
          // border: "1px solid #ffffff",
          borderRadius: "5px",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          // backgroundColor: "yellow",
        }}
      >
        <Avatar
          style={{
            width: 32,
            height: 32,
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          {...stringAvatar("Employer Name")}
        />
      </Box>
    </>
  );
  const navBarItemsRightDesktop = (
    <>
      <Box
        sx={{
          padding: "2px 8px 2px 1px",
          display: "flex",
          alignItems: "center",
          // backgroundColor: "yellow",
          position: "relative",
        }}
        className="divider_w_e"
      >
        <Typography
          sx={{ fontSize: { sm: "12px" }, cursor: "pointer" }}
          onClick={() => setOpenCreateJobDialog(true)}
        >
          Create Job
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "2px 8px 2px 10px",
          display: "flex",
          alignItems: "center",
          // backgroundColor: "yellow"
        }}
      >
        <Avatar
          style={{
            width: 32,
            height: 32,
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          {...stringAvatar("Employer Name")}
        />
      </Box>
    </>
  );

  const changingOnScroll = () => {
    if (window.scrollY >= 1) {
      setAppBar(true);
    } else {
      setAppBar(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", changingOnScroll);
    return () => {
      window.removeEventListener("scroll", changingOnScroll);
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          backgroundColor: `${aBar ? "#1A164B" : "#272361"} !important`,

          boxShadow: `${!aBar ? "none" : ""}`,
          transitionProperty: "all",
          transitionDuration: "0.6s",
          transitionDelay: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <StyledToolbar>
          {/* logo section */}
          <NavItemsAndLogo navItems={employerNavItems} />
          <Box
            sx={{
              // display: "flex",
              display: { xs: "none", sm: "flex" },
            }}
          >
            {navBarItemsRightDesktop}
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none" },
              // backgroundColor: "pink"
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflow: "hidden",
              backgroundColor: "#1A164B",
            },
          }}
        >
          <NavDrawer
            handleDrawerToggle={handleDrawerToggle}
            navItems={employerNavItems}
            navBarItemsRightMobile={navBarItemsRightMobile}
          />
        </Drawer>
      </Box>

      <Dialog
        open={openCreateJobDialog}
        onClose={() => {
          setOpenCreateJobDialog(false);
        }}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "10px",
            backgroundColor: "#272361",
            // width: "35rem",
            // height: "35rem",
          },
        }}
      >
        <DialogActions sx={{ padding: "0" }}>
          <IconButton onClick={() => setOpenCreateJobDialog(false)}>
            <CloseIcon fontSize="large" sx={{ fill: "#D9D9D9" }} />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <EmployerCreateJob />
        </DialogContent>
      </Dialog>
      {/* AVATAR */}

      <Popover
        id={avatarId}
        open={openAvatar}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>Go to dashboard</Typography>
        <Typography sx={{ p: 2, cursor: "pointer" }} onClick={handleLogout}>
          Logout
        </Typography> */}

        <UserPopover />
      </Popover>
    </Box>
  );
};
export default EmployerNavBar;
