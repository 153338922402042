import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import dialogReducer from "./dialogSlice";
import seeAllReducer from "./seeAllSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    dialog: dialogReducer,
    seeAll: seeAllReducer,
  },
});
export default store;
