import { Box, Typography, Grid } from "@mui/material";

import "./popup_form.styles.scss";
function JobApplicationForm() {
  return (
    <Box className="popup" width="75%">
      <Typography
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
          color: "white",
          marginBottom: "1.5rem",
        }}
      >
        Job Application
      </Typography>
      <Box className="popup__form">
        <form>
          {/* first row */}
          <Grid container spacing={2} columnSpacing={3}>
            <Grid item xs={6} md={4}>
              <label htmlFor="first_name" style={{ color: "#F15B24" }}>
                <Typography>First Name</Typography>
              </label>

              <input
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="middle_name" style={{ color: "#F15B24" }}>
                <Typography>Middle Name</Typography>
              </label>

              <input
                type="text"
                id="middle_name"
                name="middle_name"
                placeholder="Middle Name"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="last_name" style={{ color: "#F15B24" }}>
                <Typography>Last Name</Typography>
              </label>

              <input
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                className="popup__form--input"
              />
            </Grid>

            {/* second row */}

            <Grid item xs={6} md={4}>
              <label htmlFor="email" style={{ color: "#F15B24" }}>
                <Typography>Email</Typography>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="gender" style={{ color: "#F15B24" }}>
                <Typography>Gender</Typography>
              </label>

              <select id="gender" name="gender" className="popup__form--input">
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="height" style={{ color: "#F15B24" }}>
                <Typography>Height</Typography>
              </label>

              <input
                type="number"
                step="0.01"
                id="height"
                name="height"
                placeholder="Heigh(6.0ft)"
                className="popup__form--input"
              />
            </Grid>

            {/* Third row */}

            <Grid item xs={6} md={4}>
              <label htmlFor="religion" style={{ color: "#F15B24" }}>
                <Typography>Religion </Typography>
              </label>

              <input
                type="text"
                id="religion"
                name="religion"
                placeholder="Religion"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="phone_number" style={{ color: "#F15B24" }}>
                <Typography>Phone Number</Typography>
              </label>

              <input
                type="number"
                id="phone_number"
                name="phone_number"
                placeholder="Phone Number"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="passport_number" style={{ color: "#F15B24" }}>
                <Typography>Passport</Typography>
              </label>

              <input
                type="text"
                id="passport_number"
                name="passport_number"
                placeholder="Passport Number"
                className="popup__form--input"
              />
            </Grid>

            {/* Fourth row */}

            <Grid item xs={6} md={4}>
              <label htmlFor="nationality" style={{ color: "#F15B24" }}>
                <Typography>Nationality </Typography>
              </label>

              <input
                type="text"
                id="nationality"
                name="nationality"
                placeholder="Nationality"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="qualification" style={{ color: "#F15B24" }}>
                <Typography>Qualification</Typography>
              </label>

              <input
                type="text"
                id="text"
                name="qualification"
                placeholder="Qualification"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="date_of_birth" style={{ color: "#F15B24" }}>
                <Typography>Date of Birth</Typography>
              </label>

              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                placeholder="Date of Birth"
                className="popup__form--input"
              />
            </Grid>

            {/* Fifth row */}
            <Grid item xs={6} md={4}>
              <label htmlFor="village" style={{ color: "#F15B24" }}>
                <Typography>Village </Typography>
              </label>

              <input
                type="text"
                id="village"
                name="village"
                placeholder="Village"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="municipality" style={{ color: "#F15B24" }}>
                <Typography>Municipality</Typography>
              </label>

              <input
                type="text"
                id="municipality"
                name="municipality"
                placeholder="Municipality"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="province" style={{ color: "#F15B24" }}>
                <Typography>Province</Typography>
              </label>

              <input
                type="text"
                id="province"
                name="province"
                placeholder="Province"
                className="popup__form--input"
              />
            </Grid>

            {/* Sixth row */}

            <Grid item xs={6} md={4}>
              <label htmlFor="country" style={{ color: "#F15B24" }}>
                <Typography>Country </Typography>
              </label>

              <input
                type="text"
                id="country"
                name="country"
                placeholder="Country"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="upload_cv" style={{ color: "#F15B24" }}>
                <Typography>Upload Cv</Typography>
              </label>

              <input
                type="file"
                id="upload_cv"
                name="upload_cv"
                placeholder="Upload Cv"
                className="popup__form--input"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="passport_photo" style={{ color: "#F15B24" }}>
                <Typography>Passport Photo</Typography>
              </label>

              <input
                type="file"
                id="passport_photo"
                name="passport_photo"
                placeholder="Passport Photo"
                className="popup__form--input"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button className="popup__form--button">Submit</button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default JobApplicationForm;
