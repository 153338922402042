export const JobList = [
  {
    id: 1,
    job_title: "Software Engineer",
    demand: "High",
    deadline: "2023-07-15",
    age: 28,
  },
  {
    id: 2,
    job_title: "Data Scientist",
    demand: "Medium",
    deadline: "2023-08-10",
    age: 32,
  },
  {
    id: 3,
    job_title: "Marketing Manager",
    demand: "High",
    deadline: "2023-07-31",
    age: 35,
  },
  {
    id: 4,
    job_title: "Graphic Designer",
    demand: "Low",
    deadline: "2023-09-05",
    age: 26,
  },
  {
    id: 5,
    job_title: "Financial Analyst",
    demand: "Medium",
    deadline: "2023-08-20",
    age: 30,
  },
];
export const ApplicantList = [
  {
    id: 1,
    name: "John Doe",
    title: "Software Engineer",
    date: "2023-06-28",
    age: 32,
  },
  {
    id: 2,
    name: "Jane Smith",
    title: "Data Scientist",
    date: "2023-06-28",
    age: 28,
  },
  {
    id: 3,
    name: "Alice Johnson",
    title: "Marketing Manager",
    date: "2023-06-28",
    age: 35,
  },
  {
    id: 4,
    name: "Bob Williams",
    title: "Graphic Designer",
    date: "2023-06-28",
    age: 26,
  },
  {
    id: 5,
    name: "Emily Davis",
    title: "Financial Analyst",
    date: "2023-06-28",
    age: 30,
  },
];
