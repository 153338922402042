import React from "react";

import WorkerApplicationView from "src/views/worker_application";
import Footer from "src/layouts/footer/footer.components";

function WorkerApplicationPage() {
  return (
    <>
      <WorkerApplicationView />
      <Footer />
    </>
  );
}
export default WorkerApplicationPage;
