import React from "react";

import WorkerHomeView from "src/views/worker_home";
import Footer from "../../layouts/footer/footer.components";

function EmployerHomePage() {
  return (
    <>
      <WorkerHomeView />
      <Footer />
    </>
  );
}

export default EmployerHomePage;
