import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  role: "guest",
  status: "idle",
  error: null,
  loading: false,
};

// CUSTOM LOGIN
export const loginUser = createAsyncThunk("/login", async (user) => {
  console.log(user);
  const { selectedForm } = user;
  try {
    if (selectedForm === "worker") {
      // todo login request
      const data = {
        user: "worker",
        role: "worker",
      };

      return { data: data };
    } else if (selectedForm === "employer") {
      const data = {
        user: "employer",
        role: "employer",
      };
      return { data: data };
    } else {
      const data = {
        user: "guest",
        role: "guest",
      };
      return { data: data };
    }
  } catch (error) {
    console.log(error);
  }
});

// export const LoginWithAuth0 = async (args) => {
//   const { provider, selectedForm } = args;
//   console.log(provider, selectedForm);

//   const { loginWithRedirect } = useAuth0();
//   loginWithRedirect({
//     screen_hint: "signup",
//     authorizationParams: {
//       connection: provider,
//     },
//   });
//   const { user } = useAuth0();
//   const { email, name, picture } = user;
//   console.log(email, name, picture);

//   //todo login reqeust
//   if (selectedForm === "employer") {
//     const data = {
//       user: "employer",
//       role: "employer",
//     };
//     return { data: data };
//   } else if (selectedForm === "worker") {
//     const data = {
//       user: "worker",
//       role: "worker",
//     };
//     return { data: data };
//   } else {
//     const data = {
//       user: "guest",
//       role: "guest",
//     };
//     return { data: data };
//   }
// };

//SIGNUP
export const signupUser = createAsyncThunk("/signup", async (user) => {
  const { selectedForm } = user;
  console.log(selectedForm);
  try {
  } catch (error) {
    console.log(error);
  }
});

export const logout = createAsyncThunk("/logout", async () => {
  console.log("Lgou");
  try {
    //todo logout request
    console.log("loggin out");

    return;
  } catch (error) {
    console.log(error);
  }
});
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // logoutUser: (state) => {
    //   state.user = null;
    //   state.role = "guest";
    // },
    setRole: (state, action) => {
      state.role = action.payload;
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // User Login
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.user = action.payload.data.user;
        state.role = action.payload.data.role;
        console.log(action.payload.data.role);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message;
      })

      // User logout
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state, action) => {
        console.log("logout fulfilled");
        state.loading = false;
        state.status = "succeeded";
        state.user = null;
        state.role = "guest";
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { setRole } = authSlice.actions;
export default authSlice.reducer;
