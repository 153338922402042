import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUnskilledListLoading: false,
  unSkilledNationalList: [],
  unSkilledInternationalList: [],

  isSkilledListLoading: false,
  skilledNationalList: [],
  skilledInternationalList: [],
};

const seeAllSlice = createSlice({
  name: "seeAll",
  initialState,
});

export default seeAllSlice.reducer;
