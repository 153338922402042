import React, { useState } from "react";
import { Box, InputAdornment, Typography, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ApplicantListTable from "./applicant_list_table";
import { ApplicantList } from "./employer_home_data";
function SecondSectionApplicationList() {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <Box>
      {/* Search bar and title */}
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Box width="60%">
          <Typography
            sx={{
              fontSize: "1.3rem",
              color: "#F15B24",
              padding: "0.2rem",
              borderBottom: "2px solid #F15B24",
              width: "fit-content",
            }}
          >
            Applicant List
          </Typography>
        </Box>
        <Box width="40%">
          <TextField
            id="standard-search"
            label="Search By Title"
            type="search"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      {/* Table */}
      <Box marginTop="1.5rem">
        <ApplicantListTable data={ApplicantList} />
      </Box>
    </Box>
  );
}

export default SecondSectionApplicationList;
