import React from "react";
import { Helmet } from "react-helmet-async";

import Footer from "../../layouts/footer/footer.components";
import EmployerInstructionView from "src/views/employer_instruction";

function EmployerInstructionPage() {
  return (
    <>
      <Helmet>
        <title>Employer | API</title>
        <meta name="description" content="Employer Progress API" />
      </Helmet>
      <EmployerInstructionView />
      <Footer />
    </>
  );
}
export default EmployerInstructionPage;
