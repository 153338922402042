import React from "react";
import { Container, Box } from "@mui/material";

import FirstSectionUnskilledList from "./components/first_section_unskilled_list";

function SeeAllUnskilledView() {
  return (
    <Box component="main" sx={{ backgroundColor: "primary.subColor" }}>
      <Box component="section">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "6rem",
          }}
          disableGutters
        >
          <FirstSectionUnskilledList />
        </Container>
      </Box>
    </Box>
  );
}

export default SeeAllUnskilledView;
