import React from "react";
import { Helmet } from "react-helmet-async";

// import Contact from "../../components/contact/contact.components";
import ContactView from "src/views/contact";
import Footer from "src/layouts/footer/footer.components";

function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contact | API</title>
        <meta name="description" content="Api Contact Page" />
      </Helmet>
      <ContactView />
      <Footer />
    </>
  );
}

export default ContactPage;
