import { useNavigate } from "react-router-dom";

//IMPORT AUTH
import { useAuth0 } from "@auth0/auth0-react";

//IMPORT MATERIAL UI
import { Box, Typography, Stack } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import "./popup_form.styles.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/authSlice";
import { openSignupDialog, closeLoginDialog } from "../../redux/dialogSlice";
function LoginPopupForm() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { loginWithRedirect, user } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedForm = useSelector((state) => state.dialog.selectedForm);
  // const role = useSelector((state) => state.auth.role);

  function openSignupForm() {
    // setOpenLoginForm(false);
    // setOpenSignupForm(true);
    dispatch(closeLoginDialog());
    dispatch(openSignupDialog());
  }
  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginUser({ userName, password, selectedForm }))
      .unwrap() // <-- async Thunk returns a promise, that can be 'unwrapped')
      .then(() => navigate(`/${selectedForm}`));
    dispatch(closeLoginDialog());
  }
  async function handleLoginWithAuth(provider) {
    loginWithRedirect({
      screen_hint: "signup",
      authorizationParams: {
        connection: provider,
      },
    });
    console.log(user);
  }
  return (
    <Box className="popup" style={{ marginTop: "2rem" }}>
      <Typography sx={{ color: "white", fontSize: "1.5rem" }}>
        Login To Your Account
        <sub style={{ fontSize: "12px" }}>
          {" "}
          ({selectedForm.charAt(0).toUpperCase() + selectedForm.slice(1)})
        </sub>
      </Typography>

      <Box className="popup__form" style={{ marginTop: "1.5rem" }}>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username" style={{ color: "#F15B24" }}>
              <Typography>Username</Typography>
            </label>

            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              className="popup__form--input"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lpassword" style={{ color: "#F15B24" }}>
              <Typography>Password</Typography>
            </label>

            <input
              type="password"
              id="lpassword"
              name="password"
              placeholder="Password"
              className="popup__form--input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Typography
              sx={{ fontSize: "10px", textAlign: "end", color: "white" }}
            >
              Forgot Password?
            </Typography>
          </div>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button className="popup__form--button">Login</button>
          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography
            sx={{ color: "white", fontSize: "14px", textAlign: "center" }}
          >
            Don't have an account?{" "}
            <strong
              onClick={openSignupForm}
              style={{
                fontSize: "16px",

                cursor: "pointer",
              }}
            >
              Create Account
            </strong>
          </Typography>
          <Stack
            direction="row"
            width="80%"
            spacing={2}
            sx={{
              border: "2px solid white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            justifyContent={"center"}
            padding="4px 0px"
            alignItems="center"
            onClick={() => handleLoginWithAuth("facebook")}
          >
            <FacebookRoundedIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Facebook
            </Typography>
          </Stack>

          <Stack
            direction="row"
            width="70%"
            spacing={2}
            sx={{
              border: "2px solid white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            justifyContent={"center"}
            padding="3px 0px"
            alignItems="center"
            onClick={() => handleLoginWithAuth("google-oauth2")}
          >
            <GoogleIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Google
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginPopupForm;
