import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Button } from "@mui/material";

import apiLogo from "src/assets/images/api_logo_100x100.png";

function PageNotFoundView() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="false" disableGutters={true} sx={{ height: "80vh" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          border: "1px solid red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img
            src={apiLogo}
            alt="API"
            style={{ width: "4rem", height: "4rem" }}
          />
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: "2.5rem", md: "5rem", textAlign: "center" } }}
          >
            404 :(
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: "1.85rem", md: "3rem", textAlign: "center" } }}
        >
          Page not found
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: "0.5rem",
            borderRadius: "1rem",
            padding: "0.4rem 1rem",
            backgroundColor: "#000",
            ":hover": {
              backgroundColor: "#3b3b3b",
            },
          }}
          onClick={() => navigate("/")}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}

export default PageNotFoundView;
