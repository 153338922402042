import React from "react";
import {
  Box,
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TextField,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
function SecondSectionProgressTable() {
  return (
    <Box
      backgroundColor="#272361
    "
    >
      <Box display="flex" justifyContent="space-between" padding="1rem 0">
        <Box
          sx={{
            backgroundColor: "#f15b24",
            borderTopRightRadius: "2px",
            borderBottomRightRadius: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "6rem", md: "9rem", lg: "12rem" },
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: { xs: "14px", sm: "1.1rem" },

              color: "#fff",
              textAlign: "start",
            }}
          >
            Status
          </Typography>
        </Box>

        <Box width="40%">
          <TextField
            id="standard-search"
            label="Search By Title"
            type="search"
            variant="standard"
            fullWidth
            sx={{
              input: {
                color: "white",

                borderTopLeftRadius: "2px",
                borderBottomLeftRadius: "2px",
              },
              "& .MuiInput-underline::before": {
                borderBottomColor: "white",
              },
            }}
            // value={searchTerm}
            // onChange={(e) => setSearchTerm(e.target.value)}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            color="secondary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "white" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "#f15b24",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "6rem", md: "9rem", lg: "12rem" },
          }}
        >
          <select
            id="type"
            name="type"
            style={{
              height: "2rem",
              width: "100%",
              backgroundColor: "#f15b24",
              color: "#fff",
              border: "none",
              outline: "none",
              fontSize: "1.1rem",
            }}
          >
            <option value="medical">Medical</option>
            <option value="other">Other</option>
          </select>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Name
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Category
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Title
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Applied Date
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Completed
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Processing
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  1
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Amir Poudel
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Skilled
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Node
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  2021/02/20
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Interviewed
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Medical
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
}

export default SecondSectionProgressTable;
