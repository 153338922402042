import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const ProgressCard = () => {
  return (
    <Card
      sx={{
        backgroundColor: "#272361",
        minWidth: { xs: "200px", md: "230px" },
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" marginBottom="10px">
          <Typography variant="body1" style={{ color: "white" }}>
            Category:
          </Typography>
          <Typography variant="body2" style={{ color: "#F15B24" }}>
            Skilled
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="10px">
          <Typography variant="body1" style={{ color: "white" }}>
            Title:
          </Typography>
          <Typography variant="body2" style={{ color: "#F15B24" }}>
            DevOps
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" style={{ color: "white" }}>
            Total Appicant:
          </Typography>
          <Typography variant="body2" style={{ color: "#F15B24" }}>
            10
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgressCard;
