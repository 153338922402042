import React from "react";
import { Container, Box } from "@mui/material";

import FirstSectionImageSlider from "./components/first_section_image_slider";
import SecondSectionListing from "./components/second_section_listing";
import ThirdSectionFeedback from "./components/third_section_feedback";

import "./home.styles.scss";

function HomeView() {
  return (
    <main>
      <Box component="section">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disableGutters
        >
          <FirstSectionImageSlider />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "primary.subColor" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disableGutters
        >
          <SecondSectionListing />
        </Container>
      </Box>
      <Box
        component="section"
        sx={{
          backgroundColor: "primary.subColor",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "2rem",
          }}
          disableGutters
        >
          <ThirdSectionFeedback />
        </Container>
      </Box>
    </main>
  );
}

export default HomeView;
