import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

// IMPORT LAYOUT
import RootLayout from "./layouts/root_layout";

//IMPORT PAGES
import Homepage from "./pages/homepage/homepage";
import AboutPage from "./pages/aboutpage/aboutpage";
import ContactPage from "./pages/contactpage/contactpage";
import EmployerHomePage from "./pages/employerpage/employer_home.pages";
import EmployerProgressPage from "./pages/employerpage/employer_progress.pages";
import WorkerHomePage from "./pages/workerpage/worker_home.pages";
import WorkerProgressPage from "./pages/workerpage/worker_progress.pages";
import WorkerInstructionPage from "./pages/workerpage/worker_instruction.pages";
import WorkerApplicatinPage from "./pages/workerpage/worker_application.pages";
import EmployerInstructionPage from "./pages/employerpage/employer_instruction.pages";
import PageNotFound from "./pages/page_not_found/page_not_found.pages";
import UnskilledPage from "./pages/seeallpage/unskilledpage.pages";
import SkilledPage from "./pages/seeallpage/skilledpage.pages";

// IMPORT STYLES
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";

//IMPORT UTILS
import ProtectedRoute from "./utils/protected_route";

// ROLES OF USER
const ROLES = {
  EMPLOYER: "employer",
  WORKER: "worker",
};

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Homepage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="unauthorized" element={<PageNotFound />} />

        <Route path="skilled" element={<SkilledPage />} />
        <Route path="unskilled" element={<UnskilledPage />} />

        {/* PROTECTED ROUTES */}

        {/* Page Accessible only to employer */}
        <Route element={<ProtectedRoute allowedRoles={ROLES.EMPLOYER} />}>
          <Route path="employer">
            <Route index element={<EmployerHomePage />} />
            <Route path="progress" element={<EmployerProgressPage />} />
            <Route path="instruction" element={<EmployerInstructionPage />} />
          </Route>
        </Route>

        {/* Page Accessible only to worker */}
        <Route element={<ProtectedRoute allowedRoles={ROLES.WORKER} />}>
          <Route path="worker">
            <Route index element={<WorkerHomePage />} />
            <Route path="progress" element={<WorkerProgressPage />} />
            <Route path="application" element={<WorkerApplicatinPage />} />
            <Route path="instruction" element={<WorkerInstructionPage />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
