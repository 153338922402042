import React from "react";
import { Container, Box } from "@mui/material";

import FirstSectionWorkerProgressBar from "./components/first_section_worker_progress_bar";

function WorkerInstructionView() {
  return (
    <main>
      <Box
        component="section"
        sx={{
          marginTop: "8rem",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { md: "0 6.5rem", sm: "0 3rem", xs: "0 1rem" },
          }}
        >
          <FirstSectionWorkerProgressBar />
        </Container>
      </Box>
    </main>
  );
}

export default WorkerInstructionView;
