import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";

import JobDetailCard from "../cards/job_detail.cards.components";
import {
  JobDetailInternational,
  JobDetailNational,
} from "../cards/job_detail.data.components";

import "./listing.styles.scss";

const ForSkilled = ({ setOpenJobApplicationForm }) => {
  const [active, setActive] = React.useState("National");

  return (
    <Grid item container className="info_skilled">
      <Grid className="just_info" sx={{ marginBottom: "2rem" }}>
        <Box className="type">
          <Typography
            variant="body1"
            component="p"
            sx={{ fontSize: { xs: "14px", sm: "20px" }, float: "right" }}
          >
            Unskilled
          </Typography>
        </Box>
        <Box
          className="geography"
          sx={{
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{ fontSize: { xs: "12px", sm: "16px" }, cursor: "pointer" }}
            className={active === "National" ? "active__text" : ""}
            onClick={() => setActive("National")}
          >
            National
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ fontSize: { xs: "12px", sm: "16px" }, cursor: "pointer" }}
            className={active === "International" ? "active__text" : ""}
            onClick={() => setActive("International")}
          >
            International
          </Typography>
        </Box>
        <Box className="s_all">
          <Typography
            sx={{ float: "right", fontSize: { xs: "12px", sm: "16px" } }}
            component={Link}
            to="/unskilled"
            replace={true}
          >
            See All
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        spacing={1}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{
          width: { xs: "100%", sm: "100%", lg: "80%" },
          padding: { xs: "10px", sm: "20px", lg: "0px" },
          // backgroundColor: "blue",
        }}
      >
        {active === "International"
          ? JobDetailInternational.filter((jobContent, idx) => idx < 4).map(
              (jobContent) => (
                <JobDetailCard
                  key={jobContent.id}
                  jobContent={jobContent}
                  setOpenJobApplicationForm={setOpenJobApplicationForm}
                />
              )
            )
          : JobDetailNational.filter((jobContent, idx) => idx < 4).map(
              (jobContent) => (
                <JobDetailCard
                  key={jobContent.id}
                  jobContent={jobContent}
                  setOpenJobApplicationForm={setOpenJobApplicationForm}
                />
              )
            )}
      </Grid>
    </Grid>
  );
};
export default ForSkilled;
