import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Stack } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import "./popup_form.styles.scss";
import { closeSignupDialog, openLoginDialog } from "../../redux/dialogSlice";
import { signupUser } from "../../redux/authSlice";

function EmployerSignupForm() {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [registerNo, setRegisterNo] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  function openLoginForm() {
    // setOpenSignupForm(false);
    // setOpenLoginForm(true);
    dispatch(closeSignupDialog());
    dispatch(openLoginDialog());
  }
  function handleSubmit(e) {
    e.preventDefault();
    dispatch(
      signupUser({
        companyName,
        email,
        registerNo,
        licenseNo,
        password,
        selectedForm: "employer",
      })
    );
  }
  return (
    <Box className="popup" width={{ xs: "100%", md: "90%" }}>
      <Box className="popup__form">
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          {/* first row */}
          <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
            <Box width="50%">
              <label htmlFor="company_name" style={{ color: "#F15B24" }}>
                <Typography variant="body2">Company Name</Typography>
              </label>

              <input
                type="text"
                id="company_name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Company Name"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="email_e" style={{ color: "#F15B24" }}>
                <Typography variant="body2">Email</Typography>
              </label>

              <input
                type="email"
                id="email_e"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="popup__form--input"
              />
            </Box>
          </Box>

          {/* second row */}
          <Box display="flex" gap="1rem" marginBottom={"0.5rem"}>
            <Box width="50%">
              <label htmlFor="register_number" style={{ color: "#F15B24" }}>
                <Typography variant="body2">Register No.</Typography>
              </label>
              <input
                type="text"
                id="register_number"
                value={registerNo}
                onChange={(e) => setRegisterNo(e.target.value)}
                placeholder="Register No."
                className="popup__form--input"
              />{" "}
            </Box>
            <Box width="50%">
              <label htmlFor="license_number" style={{ color: "#F15B24" }}>
                <Typography variant="body2">License No.</Typography>
              </label>

              <input
                type="text"
                id="license_number"
                value={licenseNo}
                onChange={(e) => setLicenseNo(e.target.value)}
                placeholder="License No."
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Third row */}
          <Box display="flex" gap="1rem" marginBottom={"0.5rem"}>
            <Box width="50%">
              <label htmlFor="epassword" style={{ color: "#F15B24" }}>
                <Typography variant="body2">Password </Typography>
              </label>

              <input
                type="password"
                id="epassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="confirm_password" style={{ color: "#F15B24" }}>
                <Typography variant="body2">Confirm Password</Typography>
              </label>

              <input
                type="password"
                id="confirm_password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                className="popup__form--input"
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button className="popup__form--button">Signup</button>
          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{ color: "white", fontSize: "14px", textAlign: "center" }}
          >
            Already have an account?{" "}
            <strong
              onClick={openLoginForm}
              style={{ fontSize: "16px", cursor: "pointer" }}
            >
              Login
            </strong>
          </Typography>
          <Stack
            direction="row"
            width={{ xs: "80%", md: "60%" }}
            spacing={3}
            sx={{ border: "2px solid white", borderRadius: "20px" }}
            justifyContent={"center"}
            padding="3px 0px"
            alignItems="center"
          >
            <FacebookRoundedIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Facebook
            </Typography>
          </Stack>

          <Stack
            direction="row"
            width={{ xs: "95%", md: "50%" }}
            spacing={3}
            sx={{ border: "2px solid white", borderRadius: "20px" }}
            justifyContent={"center"}
            padding="3px 0px"
            alignItems="center"
          >
            <GoogleIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Google
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default EmployerSignupForm;
