import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ allowedRoles }) {
  const location = useLocation();
  const role = useSelector((state) => state.auth.role);
  const user = useSelector((state) => state.auth.user);
  return role === allowedRoles ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
}

export default ProtectedRoute;
