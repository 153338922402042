import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FeedbackIcon from "@mui/icons-material/Feedback";
import WorkerProgressImage from "src/assets/images/workerProgressInfo.png";
function FirstSectionWorkerProgressBar() {
  return (
    <Box display="flex" justifyContent="center" sx={{ position: "relative" }}>
      {/* CONTENT */}
      <Box
        sx={{
          width: { md: "55%", xs: "95%" },
          display: "flex",
          flexDirection: "column",
          gap: "1.2rem",
          zIndex: "100",
          marginLeft: { md: "14rem", sm: "10rem", xs: "5rem" },
          padding: "2rem 0",
        }}
      >
        <Box className="progress__bar progress__bar--completed">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <FolderIcon className="completed__color" />
          </Avatar>
          <Typography>Document Screening</Typography>
        </Box>

        <Box className="progress__bar progress__bar--processing">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <InterpreterModeIcon className="processing__color" />
          </Avatar>
          <Typography>Interview</Typography>
        </Box>

        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <LocalHospitalIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Medical Test</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <TextSnippetIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Orientation</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <RecordVoiceOverIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Contract Sign</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <ContactPageIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Visa Process</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <EditNoteIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Sign Contract</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <AirplaneTicketIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Ticket</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <FlightTakeoffIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Departure</Typography>
        </Box>
        <Box className="progress__bar progress__bar--notcompleted">
          <Avatar
            sx={{
              width: "2.8rem",
              height: "2.8rem",
              marginLeft: "-1rem",
              bgcolor: "white",
              border: "1px solid #272361",
            }}
          >
            <FeedbackIcon className="notcompleted__color" />
          </Avatar>
          <Typography>Feedback</Typography>
        </Box>
      </Box>

      {/* IMAGE */}
      <Box sx={{ position: "absolute", bottom: "-0.7rem", left: "0" }}>
        <Box sx={{ position: "relative" }}>
          <img
            src={WorkerProgressImage}
            alt="ProgressInfo"
            style={{ width: "100%" }}
          />
          <Box
            sx={{
              width: "60px",
              position: "absolute",
              bottom: "40px",
              left: { md: "20px", xs: "0px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "1.7rem",
                height: "1.7rem",
                backgroundColor: "#F15B24",
                boxShadow: "0px 2.2px 2px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "0.15rem",
              }}
            ></Box>
            <Typography sx={{ fontSize: "0.6rem", fontWeight: "bold" }}>
              Complete
            </Typography>
          </Box>
          <Box
            sx={{
              width: "60px",
              position: "absolute",
              bottom: "100px",
              left: { md: "20px", xs: "0px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "1.7rem",
                height: "1.7rem",
                backgroundColor: "#272361",
                boxShadow: "0px 2.2px 2px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "0.15rem",
              }}
            ></Box>
            <Typography sx={{ fontSize: "0.6rem", fontWeight: "bold" }}>
              Processing
            </Typography>
          </Box>
          <Box
            sx={{
              width: "60px",
              position: "absolute",
              bottom: "160px",
              left: { md: "20px", xs: "0px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "1.7rem",
                height: "1.7rem",
                backgroundColor: "#C8C8C8",
                boxShadow: "0px 2.2px 2px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "0.15rem",
              }}
            ></Box>
            <Typography sx={{ fontSize: "0.6rem", fontWeight: "bold" }}>
              Waiting
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FirstSectionWorkerProgressBar;
