import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Roboto Serif, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#1A164B",
      subColor: "#272361",
    },
    secondary: {
      main: "#ffffff",
      subColor: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 646,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export default theme;
