import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";

import theme from "src/theme.jsx";

import apiLogo from "src/assets/images/api_logo_100x100-removebg.png";
// import './navigation_bar.styles.scss';

const MenuNav = styled(Typography)(() => ({
  // fontSize:{sm: "12px"},//need breakpoints
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

function NavItemsAndLogo({ navItems }) {
  return (
    <>
      <Box
        sx={{
          height: { xs: "40px", sm: "60px", md: "80px" },
          width: { xs: "40px", sm: "60px", md: "80px" },
          // border: "1px solid #00ff00",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
          padding: { xs: "6px", sm: "10px", md: "14px" },
          borderRadius: "50%",
          margin: { xs: "6px", md: "10px" },
          position: "relative",
        }}
        className="logo_shape_for_desktop"
      >
        <Link to="/" replace={true}>
          <img src={apiLogo} className="logo" alt="Logo" />
        </Link>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          // border: "1px solid yellow",
          // backgroundColor: "yellow",
          gap: { sm: 2, md: 3 },
          display: { xs: "none", sm: "flex" },
          fontSize: "18px",
        }}
      >
        {navItems.map((item) => (
          <MenuNav variant="body1" key={item.url}>
            <NavLink
              to={item.url}
              className={({ isActive }) =>
                isActive ? "nav__link--active" : "nav__link"
              }
              end
            >
              {item.label}
            </NavLink>
          </MenuNav>
        ))}
      </Box>
    </>
  );
}

export default NavItemsAndLogo;
