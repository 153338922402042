import React from "react";
import { Box } from "@mui/material";
function FirstSectionWorkerHome() {
  return (
    <Box
      sx={{ width: "100%", height: "60vh", border: "1px solid black" }}
    ></Box>
  );
}
export default FirstSectionWorkerHome;
