import React from "react";

import PageNotFoundView from "src/views/page_not_found";
import Footer from "src/layouts/footer/footer.components";

function PageNotFound() {
  return (
    <>
      <PageNotFoundView />
      <Footer />
    </>
  );
}

export default PageNotFound;
