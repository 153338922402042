import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// IMPORT NAVBAR
import WorkerNavBar from "./navigation_bar/navigation _bar_worker.components";
import EmployerNavBar from "./navigation_bar/navigation_bar_employer.components";
import GuestNavBar from "./navigation_bar/navigation_bar_guest.components";

import { setRole } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

function RootLayout() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  //todo: check if user is employer or worker
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setRole("employer"));
    }
  }, [dispatch, isAuthenticated]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const role = useSelector((state) => state.auth.role);
  console.log(role);
  let navBar;
  if (location.pathname.startsWith("/employer") && role === "employer") {
    navBar = <EmployerNavBar />;
  } else if (location.pathname.startsWith("/worker") && role === "worker") {
    navBar = <WorkerNavBar />;
  } else {
    navBar = <GuestNavBar />;
  }

  return (
    <>
      {navBar}
      <Outlet />
    </>
  );
}
export default RootLayout;
