import React from "react";
import { Container, Box } from "@mui/material";
import FirstSectionAbout from "./components/first_section_about";
function AboutView() {
  return (
    <main>
      <Box
        component="section"
        sx={{
          marginTop: "6rem",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <FirstSectionAbout />
        </Container>
      </Box>
    </main>
  );
}

export default AboutView;
