import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
function ContactForm() {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      phoneNumber,
      email,
      message,
    };
    console.log("Contact form data: ", data);
  };

  return (
    <Box className="contact__form">
      <Box className="contact__form--container">
        <Typography variant="h5" align="center">
          Feel Free To Contact Us
        </Typography>
        <Box className="contact__form--wrapper">
          <form className="contact__form" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name</label>
              <br />
              <input
                type="text"
                placeholder="Name"
                id="name"
                className="form__input--text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="phone_number">Phone Number</label>
              <br />
              <input
                type="text"
                placeholder="Phone Number"
                id="phone_number"
                className="form__input--text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email_address">Email Address</label>
              <br />
              <input
                type="email"
                placeholder="Email"
                id="email_address"
                className="form__input--text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <br />
              <textarea
                placeholder="Message"
                id="message"
                rows="5"
                cols="50"
                className="form__input--text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <button type="submit" className="form__button">
              Submit
            </button>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactForm;
