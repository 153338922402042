import pp1 from "src/assets/images/f_img1.jpg";
import pp2 from "src/assets/images/f_img2.jpg";
import pp3 from "src/assets/images/f_img3.jpg";
import pp4 from "src/assets/images/f_img4.jpg";
import pp5 from "src/assets/images/f_img5.jpg";
import pp6 from "src/assets/images/f_img6.jpg";
import pp7 from "src/assets/images/f_img7.jpg";

const FeedBackInfo = [
  {
    id: 1,
    profilePic: pp1,
    name: "Rameshowor Chaurasiya",
    address: "Gaur,Rautahat",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 3.5,
  },
  {
    id: 2,
    profilePic: pp2,
    name: "Rajesh Chaudhary",
    address: "Gulariya,Rautahat",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 4.5,
  },
  {
    id: 3,
    profilePic: pp3,
    name: "Karan Rana ",
    address: "Janakipur,Bardiya",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 5,
  },
  {
    id: 4,
    profilePic: pp4,
    name: "Ganesh Rokaya",
    address: "krishnapur,kanchanpur",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 4.9,
  },
  {
    id: 5,
    profilePic: pp5,
    name: "Keshav Bist",
    address: "krishnapur,Kanchanpur",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 3.9,
  },
  {
    id: 6,
    profilePic: pp6,
    name: "Ganesh Bist",
    address: "Dauda,Doti",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 1.9,
  },
  {
    id: 7,
    profilePic: pp7,
    name: "Shyam Nagarkoti",
    address: "Sitapaila,Kathmandu",
    comments:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....",
    ratingValue: 3.7,
  },
];
export default FeedBackInfo;
