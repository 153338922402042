import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Box,
  Rating,
  Typography,
  Collapse,
} from "@mui/material";

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FeedbackCard({ feedbackComments }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        maxWidth: 255,
        width: "100%",
        minHeight: { xs: 205 },
        padding: { xs: "0.6rem", sm: "0.8rem" },
        boxShadow: "-1px 2px 5px #fff",
        borderRadius: "5px",
        backgroundColor: "primary.subColor",
        color: "white",
        border: "2px solid #272341",
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "10px", sm: "12px", md: "14px" },
        }}
      >
        {feedbackComments?.name}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "8px", sm: "10px" },
        }}
      >
        {feedbackComments?.address}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // border: "1px solid yellow",
          color: "#FFFFFF",
        }}
      >
        <Rating
          name="read-only"
          value={feedbackComments?.ratingValue}
          precision={0.1}
          readOnly
          sx={{
            fontSize: { xs: "10px", sm: "12px" },
            width: "74%",
            display: "flex",
            alignItems: "center",
            color: "#F15B24",
            "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
              color: "#ffffff",
            },
            "& .MuiRating-iconFilled .MuiSvgIcon-root": {
              color: "#F15B24",
            },
          }}
        />
        <Typography
          sx={{
            width: "26%",
            float: "right",
            fontSize: "12px",
          }}
        >
          <span
            style={{ color: "#F15B24", fontWeight: "bold", fontSize: "13px" }}
          >
            {feedbackComments?.ratingValue}
          </span>
          /5
        </Typography>
      </Box>
      <CardContent sx={{ padding: "0.2rem" }}>
        <Typography
          variant="body2"
          sx={{ color: "white", fontSize: { xs: "10px", sm: "12px" } }}
        >
          {feedbackComments?.comments.slice(0, 150)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ padding: "0" }}>
        <ExpandMore
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {/* <ExpandMoreIcon sx={{ fill: "white" }} /> */}
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "13px" },
              color: "#F15B24",
            }}
          >
            {expanded ? "See Less" : "See More"}
          </Typography>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "12px" },
            }}
          >
            {feedbackComments?.comments}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
