import React from "react";

import WorkerProgressView from "src/views/worker_progress";
import Footer from "../../layouts/footer/footer.components";

function EmployerProgressPage() {
  return (
    <>
      <WorkerProgressView />
      <Footer />
    </>
  );
}

export default EmployerProgressPage;
