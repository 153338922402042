import React from "react";
import { Helmet } from "react-helmet-async";

import AboutView from "src/views/about";
import Footer from "src/layouts/footer/footer.components";

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About | API</title>
        <meta name="description" content="Api About Page" />
      </Helmet>
      <AboutView />
      <Footer />
    </>
  );
}

export default AboutPage;
