import CIC from '../../assets/images/documents_for_app/cic.jpg'
import CRC from '../../assets/images/documents_for_app/crc.jpg'
import DFA from '../../assets/images/documents_for_app/dfa.jpg'
import BLD from '../../assets/images/documents_for_app/bLd.jpg'
import License from '../../assets/images/documents_for_app/renew_licence_3.png'

const certificateData = [
    {
        id: 1,
        title: "Certificate Of Incorporation Of Company",
        doc: CIC
    },
    {
        id: 2,
        title: "Office Of The Company Registrar",
        doc: CRC
    },
    {
        id: 3,
        title: "Department Of Foreign Employment",
        doc: DFA
    },
    {
        id: 4,
        title: "Bank Legal Document",
        doc: BLD
    },
    {
        id: 5,
        title: "License",
        doc: License
    },
];

export default certificateData;
