import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import Arrows from "./arrows";
import Dots from "./dots";
import Slider from "./slider";
import SlideContent from "./sliderContent";

import "./slider.styles.scss";

// const useStyles = makeStyles((theme) => ({
//     imgSlider: {
//         height: "auto",
//         width: "100%",
//         backgroundImage: "linear-gradient(to left,#a693aa,#d9d1ee,#efeaf8,#faf9fc)",
//         // opacity: 0.6,
//         position: "relative",
//         padding: "10px",
//         // display: "flex",
//     },
// }));
const len = SlideContent.length - 1;
const Sliding = () => {
  // const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);
  return (
    <Grid
      container
      sx={{ backgroundColor: "green", width: "100%", height: "100%" }}
    >
      <Slider activeIndex={activeIndex} />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        onClick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </Grid>
  );
};
export default Sliding;
