import React from "react";
import { Helmet } from "react-helmet-async";

import Footer from "../../layouts/footer/footer.components";
import EmployerHomeView from "src/views/employer_home";

function EmployerHomePage() {
  return (
    <>
      <Helmet>
        <title>Employer | API</title>
        <meta name="description" content="Api About Page" />
      </Helmet>
      <EmployerHomeView />
      <Footer />
    </>
  );
}

export default EmployerHomePage;
