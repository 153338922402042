import React from "react";

//IMPORT MATERIAL UI
import { Box, Grid, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

//IMPORT COMPONENTS
// import img1 from '../../images/img1.jpg';
import Sliding from "./sliding.components";

import "./imageSlider.styles.scss";
const ImageSlider = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: { xs: "50px", sm: "68px", md: "100px" },
        height: { xs: "200px", sm: "400px", md: "450px", lg: "700px" },
      }}
      className="img-container"
    >
      <Box className="image-slider">
        <Sliding />
      </Box>
      <Box
        className="search-bar"
        sx={{
          height: { xs: "24px", sm: "50px" },
          backgroundColor: "#ffffff",
          padding: { xs: "2px 0px 2px 6px", sm: "4px 10px" },
        }}
      >
        <input
          type="text"
          placeholder="Search by Job Title,Skill and Company"
          className="search-input"
        />
        <IconButton>
          <SearchIcon
            sx={{
              backgroundColor: "#d9d9d9",
              color: "#000000",
              fontSize: { xs: "20px", sm: "36px" },
              padding: "4px",
              borderRadius: "50%",
            }}
          />
        </IconButton>
      </Box>
    </Grid>
  );
};
export default ImageSlider;
