import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDialog: false,
  signupDialog: false,
  selectedForm: "",
  createJobDialog: false,
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openLoginDialog: (state) => {
      state.loginDialog = true;
    },
    closeLoginDialog: (state) => {
      state.loginDialog = false;
    },
    openSignupDialog: (state) => {
      state.signupDialog = true;
    },
    closeSignupDialog: (state) => {
      state.signupDialog = false;
    },
    openCreateJobDialog: (state) => {
      state.createJobDialog = true;
    },
    closeCreateJobDialog: (state) => {
      state.createJobDialog = false;
    },
    selectedFormWorker: (state) => {
      state.selectedForm = "worker";
    },
    selectedFormEmployer: (state) => {
      state.selectedForm = "employer";
    },
  },
});
export const {
  openLoginDialog,
  closeLoginDialog,
  openSignupDialog,
  closeSignupDialog,
  selectedFormEmployer,
  selectedFormWorker,
  openCreateJobDialog,
  closeCreateJobDialog,
} = dialogSlice.actions;
export default dialogSlice.reducer;
