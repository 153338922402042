import React from "react";

// MUI IMPORTS
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  Avatar,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

// REDUX IMPORTS
// import { logout } from "../../redux/authSlice";

//COMPONENT
import UserPopover from "../account_popover/account_popover";

// STYLES AND ASSETS
import "./navigation_bar.styles.scss";

// UTILS
import { stringAvatar } from "../../utils/formatName";
import NavDrawer from "./nav_drawer";
import NavItemsAndLogo from "./nav_items_and_logo";
const drawerWidth = 140;

// WORKER NAVIGATION ITEMS
const workerNavItems = [
  { label: "Home", url: "/worker" },
  { label: "Instruction", url: "/worker/instruction" },
  { label: "Application", url: "/worker/application" },
  { label: "Progress", url: "/worker/progress" },
  // { label: "Contact", url: "/contact" },
];

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "green",
  // backgroundColor: "transparent",
  "&.MuiToolbar-root": {
    backgroundColor: "transparent",
  },
});

const WorkerNavBar = () => {
  const [aBar, setAppBar] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openAvatar = Boolean(anchorEl);
  const avatarId = openAvatar ? "simple-popover" : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const handleLogout = async (e) => {
  //   dispatch(logout());
  //   navigate("/");
  // };
  //custom drawer

  const navBarItemsRightMobile = (
    <Box
      sx={{
        // width: "120px",
        // border: "1px solid #ffffff",
        borderRadius: "5px",
        padding: "2px",
        display: "flex",
        alignItems: "center",
        // backgroundColor: "yellow",
      }}
    >
      <Box
        sx={{
          // width: "120px",
          // border: "1px solid #ffffff",
          borderRadius: "5px",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          // backgroundColor: "yellow",
        }}
      >
        <Avatar
          style={{
            width: 32,
            height: 32,
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          {...stringAvatar("Worker Name")}
        />
      </Box>
    </Box>
  );
  const navBarItemsRightDesktop = (
    <Box
      sx={{
        padding: "2px 8px 2px 10px",
        display: "flex",
        alignItems: "center",
        // backgroundColor: "yellow"
      }}
    >
      <Avatar
        style={{
          width: 32,
          height: 32,
          cursor: "pointer",
          fontSize: "14px",
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...stringAvatar("Worker Name")}
      />
    </Box>
  );
  const changingOnScroll = () => {
    if (window.scrollY >= 1) {
      setAppBar(true);
    } else {
      setAppBar(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", changingOnScroll);
    return () => {
      window.removeEventListener("scroll", changingOnScroll);
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          backgroundColor: `${aBar ? "#1A164B" : "#272361"} !important`,

          boxShadow: `${!aBar ? "none" : ""}`,
          transitionProperty: "all",
          transitionDuration: "0.6s",
          transitionDelay: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <StyledToolbar>
          {/* logo section */}
          <NavItemsAndLogo navItems={workerNavItems} />
          <Box
            sx={{
              // display: "flex",
              display: { xs: "none", sm: "flex" },
            }}
          >
            {navBarItemsRightDesktop}
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none" },
              // backgroundColor: "pink"
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflow: "hidden",
              backgroundColor: "#1A164B",
            },
          }}
        >
          <NavDrawer
            handleDrawerToggle={handleDrawerToggle}
            navItems={workerNavItems}
            navBarItemsRightMobile={navBarItemsRightMobile}
          />
        </Drawer>
      </Box>

      <Popover
        id={avatarId}
        open={openAvatar}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UserPopover />
      </Popover>
    </Box>
  );
};
export default WorkerNavBar;
