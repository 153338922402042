import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import BookmarkIcon from "@mui/icons-material/Bookmark";
function FirstSectionWorkerApplication() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ fontWeight: "bold" }}>ID: </Typography>
      <Box
        sx={{
          border: "1px solid black",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          padding: "2rem",
          flexDirection: { md: "row", xs: "column" },
          gap: "3rem",
        }}
      >
        <Box sx={{ width: { md: "50%", xs: "95%" } }}>
          <Typography sx={{ fontWeight: "bold" }}>Worker Details</Typography>

          <Box sx={{ display: "flex", gap: "0.5rem", marginTop: "1rem" }}>
            <Typography variant="body1">Name: </Typography>
            <Typography variant="body1">Ram Bahadur</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Gender: </Typography>
            <Typography variant="body1">Male</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Nationality: </Typography>
            <Typography variant="body1">Nepali</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Date of birth: </Typography>
            <Typography variant="body1">2034/12/12</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Height: </Typography>
            <Typography variant="body1">5.5ft</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Weight: </Typography>
            <Typography variant="body1">65KG</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Qualification: </Typography>
            <Typography variant="body1"></Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Address: </Typography>
            <Typography variant="body1">Ktm</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Passport No: </Typography>
            <Typography variant="body1">4747w57</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Phone No: </Typography>
            <Typography variant="body1">7478541524</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Email: </Typography>
            <Typography variant="body1">rambahadur@gmail.com</Typography>
          </Box>
        </Box>
        <Box sx={{ width: { md: "50%", xs: "80%" } }}>
          <Typography sx={{ fontWeight: "bold" }}>Employer Details</Typography>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Company Name: </Typography>
            <Typography variant="body1">ABC company</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Country: </Typography>
            <Typography variant="body1">Qatar</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Salary: </Typography>
            <Typography variant="body1">NRs. 254789</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Job Title: </Typography>
            <Typography variant="body1">Chef</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Accomodation: </Typography>
            <Typography variant="body1">Yes</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Fooding: </Typography>
            <Typography variant="body1">Yes</Typography>
          </Box>{" "}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Contract: </Typography>
            <Typography variant="body1">3yrs</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Typography variant="body1">Ticket: </Typography>
            <Typography variant="body1">Ready</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
        <Button variant="contained" endIcon={<PrintIcon />}>
          Print
        </Button>
        <Button variant="contained" endIcon={<EditIcon />}>
          Edit{" "}
        </Button>
        <Button variant="contained" endIcon={<BookmarkIcon />}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default FirstSectionWorkerApplication;
