import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Box, Stack, Divider } from "@mui/material";

import apiLogo from "src/assets/images/api_logo.png";
const drawerWidth = 140;

function NavDrawer({ handleDrawerToggle, navItems, navBarItemsRightMobile }) {
  return (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        backgroundColor: "primary.main",
        color: "secondary.main",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          height: "40px",
          width: "40px",
          padding: { xs: "6px", md: "14px" },
          backgroundColor: "#ffffff",
          borderRadius: "50%",
          margin: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
        className="logo_shape"
      >
        <Link to="/" replace={true}>
          <img src={apiLogo} className="logo" alt="Logo" />
        </Link>
      </Box>
      <Divider />
      <Stack direction="column" sx={{ position: "relative", height: "91.3vh" }}>
        <Stack
          spacing={2}
          sx={{
            padding: "8px 0px 0px 8px",
            fontSize: "18px",
            background: "transparent",
          }}
        >
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              // variant="body1"
              // component={NavLink}
              to={item.url}
              className={(isActive) =>
                isActive ? "nav__link--active" : "nav__link"
              }
              end
            >
              {item.label}
            </NavLink>
          ))}
        </Stack>
        {/* Nav Item Right Mobile */}
        <Box
          sx={{
            position: "absolute",
            bottom: "12%",
            width: drawerWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {navBarItemsRightMobile}
        </Box>
      </Stack>
    </Box>
  );
}
export default NavDrawer;
