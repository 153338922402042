import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Slider from "react-slick";

import FeedbackCard from "./feedback_card";
import FeedBackInfo from "./feedBackDetail.jsx";

const ThirdSectionFeedback = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  return (
    <>
      <Grid container>
        <Grid item className="f_just_info" sx={{ marginBottom: "2rem" }}>
          <Box className="feedback-title">
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: { xs: "14px", sm: "20px" },
                float: "right",
              }}
            >
              Feedback
            </Typography>
          </Box>
          <Box className="f_s_all">
            <Typography
              sx={{ float: "right", fontSize: { xs: "12px", sm: "16px" } }}
            >
              &nbsp;
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "100%", lg: "80%" } }}>
          <Slider {...settings} style={{ width: "100%" }}>
            {FeedBackInfo.map((feedBackDetail) => (
              <FeedbackCard
                key={feedBackDetail.id}
                feedbackComments={feedBackDetail}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  );
};
export default ThirdSectionFeedback;
