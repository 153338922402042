import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./popup_form.styles.scss";
function EmployerSignupForm() {
  return (
    <Box className="popup" width={{ xs: "90%", md: "75%" }}>
      <Typography
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
          color: "white",
          marginBottom: "1.5rem",
        }}
      >
        Job Detail
      </Typography>
      <Box className="popup__form">
        <form style={{ width: "100%" }}>
          {/* first row */}
          <Box style={{ display: "flex", gap: "1rem" }}>
            <Box width="50%">
              <label htmlFor="job__title" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Job Title
                </Typography>
              </label>

              <input
                type="text"
                id="job__title"
                name="job__title"
                placeholder="Job Title"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="type" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Type
                </Typography>
              </label>

              <select id="type" name="type" className="popup__form--input">
                <option value="unskilled">Unskilled</option>
                <option value="skilled">Skilled</option>
              </select>
            </Box>
          </Box>
          {/* second row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="no_of_demand" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  No. of Demand
                </Typography>
              </label>
              <input
                type="number"
                id=""
                name="no_of_demand"
                placeholder="No. of Demand"
                className="popup__form--input"
              />{" "}
            </Box>
            <Box width="50%">
              <label htmlFor="working_hours" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Working hours per Week
                </Typography>
              </label>

              <input
                type="number"
                id="working_hours"
                name="working_hours"
                placeholder="Working Hour(48hr)"
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Third row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="salary" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Salary{" "}
                </Typography>
              </label>

              <input
                type="text"
                id="salary"
                name="salary"
                placeholder="Salary"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="age" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Age
                </Typography>
              </label>

              <input
                type="number"
                id="age"
                name="age"
                placeholder="Age"
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Fourth Row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="contract__year" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Contract Year{" "}
                </Typography>
              </label>

              <input
                type="text"
                id="contract__year"
                name="contract__year"
                placeholder="Contract Year"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="age" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Overtime
                </Typography>
              </label>

              <input
                type="text"
                id="overtime"
                name="overtime"
                placeholder="Overtime"
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Fifth Row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="deadline" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Deadline{" "}
                </Typography>
              </label>

              <input
                type="date"
                id="deadline"
                name="deadline"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="ticket" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Ticket
                </Typography>
              </label>

              <input
                type="text"
                id="ticket"
                name="ticket"
                placeholder="Ticket"
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Sixth Row */}
          <Box display="flex" gap="1rem">
            <Box
              width="50%"
              display="flex"
              alignItems={"center"}
              sx={{
                flexDirection: { md: "row", xs: "column" },
                gap: { md: "1rem", xs: "0.5rem" },
              }}
            >
              <Typography sx={{ color: "white" }}>Accomodation</Typography>
              <FormControl component="fieldset">
                <RadioGroup row /*{gender} onChange={handleGenderChange}*/>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        icon={
                          <CheckBoxOutlineBlankIcon style={{ fill: "white" }} />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fill: "white" }} />}
                      />
                    }
                    label={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        Yes
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        icon={
                          <CheckBoxOutlineBlankIcon style={{ fill: "white" }} />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fill: "white" }} />}
                      />
                    }
                    label={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        No
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              width="50%"
              display="flex"
              alignItems={"center"}
              sx={{
                flexDirection: { md: "row", xs: "column" },
                gap: { md: "1rem", xs: "0.5rem" },
              }}
            >
              <Typography sx={{ color: "white" }}>Fooding</Typography>
              <FormControl component="fieldset">
                <RadioGroup row /*{gender} onChange={handleGenderChange}*/>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        icon={
                          <CheckBoxOutlineBlankIcon style={{ fill: "white" }} />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fill: "white" }} />}
                      />
                    }
                    label={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        Yes
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        icon={
                          <CheckBoxOutlineBlankIcon style={{ fill: "white" }} />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fill: "white" }} />}
                      />
                    }
                    label={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        No
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          {/* Seventh Row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="country_c" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Country{" "}
                </Typography>
              </label>

              <input
                type="text"
                id="country_c"
                name="country"
                placeholder="Country"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="state" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  State
                </Typography>
              </label>

              <input
                type="text"
                id="state"
                name="state"
                placeholder="State"
                className="popup__form--input"
              />
            </Box>
          </Box>
          {/* Eighth Row */}
          <Box display="flex" gap="1rem">
            <Box width="50%">
              <label htmlFor="city" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  City{" "}
                </Typography>
              </label>

              <input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="postal_code" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Postal Code{" "}
                </Typography>
              </label>

              <input
                type="text"
                id="postal_code"
                name="postal_code"
                placeholder="Postal Code"
                className="popup__form--input"
              />
            </Box>
            <Box width="50%">
              <label htmlFor="website" style={{ color: "#F15B24" }}>
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                >
                  Website
                </Typography>
              </label>

              <input
                type="text"
                id="website"
                name="website"
                placeholder="Website"
                className="popup__form--input"
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button className="popup__form--button">Submit</button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default EmployerSignupForm;
