import img1 from "src/assets/images/img1.jpg";
import img2 from "src/assets/images/img2.jpg";
import img3 from "src/assets/images/img3.jpg";
import img4 from "src/assets/images/img4.jpg";
import img5 from "src/assets/images/img5.jpg";
import img6 from "src/assets/images/img6.jpg";
const SlideContent = [
  {
    id: 1,
    urls: img1,
  },
  {
    id: 2,
    urls: img2,
  },
  {
    id: 3,
    urls: img3,
  },
  {
    id: 4,
    urls: img4,
  },
  {
    id: 5,
    urls: img5,
  },
  {
    id: 6,
    urls: img6,
  },
];

export default SlideContent;
