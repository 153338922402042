import React from "react";
import { Helmet } from "react-helmet-async";

//IMPORT COMPONENTS
import HomeView from "src/views/home";
import Footer from "../../layouts/footer/footer.components";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>Home | API</title>
        <meta name="description" content="Home page " />
      </Helmet>

      <HomeView />
      <Footer />
    </>
  );
};
export default Homepage;
