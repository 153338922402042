import React from "react";
import slideContent from "./sliderContent";
import "./slider.styles.scss";
const Dots = (props) => {
  return (
    <div className="all-dots">
      {slideContent.map((slide, index) => (
        <span
          key={index}
          className={`${
            props.activeIndex === index ? "dot active-dot" : "dot"
          }`}
          onClick={(event) => props.onClick((event.target.value = index))}
        ></span>
      ))}
    </div>
  );
};
export default Dots;
