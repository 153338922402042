import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  InputAdornment,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import JobDetailCard from "src/components/cards/job_detail.cards.components";
import {
  JobDetailInternational,
  JobDetailNational,
} from "src/components/cards/job_detail.data.components";

const itemsPerPage = 18; // Number of items to display per page

const FirstSectionSkilledList = ({ setOpenJobApplicationForm }) => {
  const [active, setActive] = React.useState("National");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPageNational, setCurrentPageNational] = React.useState(1);
  const [currentPageInternational, setCurrentPageInternational] =
    React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");

  // Calculate the total number of pages
  const totalPageInternational = Math.ceil(
    JobDetailInternational.length / itemsPerPage
  );
  const totalPageNational = Math.ceil(JobDetailNational.length / itemsPerPage);

  // Calculate the range of items to display based on the current page
  const startIndexInternational = (currentPageInternational - 1) * itemsPerPage;
  const startIndexNational = (currentPageNational - 1) * itemsPerPage;
  const endIndexInternational = startIndexInternational + itemsPerPage;
  const endIndexNational = startIndexNational + itemsPerPage;
  const displayedInternationalJobs = JobDetailInternational.slice(
    startIndexInternational,
    endIndexInternational
  );
  const displayedNationalJobs = JobDetailNational.slice(
    startIndexNational,
    endIndexNational
  );
  const open = Boolean(anchorEl);

  const handlePageChangeNational = (event, value) => {
    setCurrentPageNational(value);
  };
  const handlePageChangeInternational = (event, value) => {
    setCurrentPageInternational(value);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, [currentPageInternational, currentPageNational]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container className="info_skilled">
        <Grid
          item
          className="just_info"
          sx={{
            marginBottom: "2rem",
          }}
        >
          <Box className="type">
            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: { xs: "14px", sm: "20px" }, float: "right" }}
            >
              Skilled
            </Typography>
          </Box>
          <Box
            className="geography"
            sx={{
              gap: 2,
              // background: "red",
              display: "flex",
              width: "30%",
              // height: "auto",
              // paddingLeft: "1.4rem",
              // background: "red",
            }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: { xs: "12px", sm: "16px" },
                cursor: "pointer",
                color: "white",
              }}
              className={active === "National" ? "active__text" : ""}
              onClick={() => setActive("National")}
            >
              National
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: { xs: "12px", sm: "16px" },
                cursor: "pointer",
                color: "white",
              }}
              className={active === "International" ? "active__text" : ""}
              onClick={() => setActive("International")}
            >
              International
            </Typography>
          </Box>

          <Box width="50%">
            <TextField
              id="standard-search"
              label="Search By Title"
              type="search"
              variant="standard"
              sx={{
                input: { color: "white" },
                label: { color: "white" },

                width: "50%",
                color: "white",
                "& .MuiInput-underline::before": {
                  borderBottomColor: "white",
                },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                style: { fontFamily: "nunito", color: "white" },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ fill: "white" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="s_all">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Typography
                sx={{
                  float: "right",
                  fontSize: { xs: "12px", sm: "16px" },
                  cursor: "pointer",
                  color: "white",
                }}
              >
                Filter
              </Typography>
              <FilterListIcon sx={{ fill: "white" }} />
            </Stack>
          </Box>
        </Grid>
        <Grid
          container
          spacing={5}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{
            width: { xs: "100%", sm: "100%", lg: "80%" },
            padding: { xs: "10px", sm: "20px", lg: "0px" },
            // backgroundColor: "blue"
          }}
        >
          {active === "International"
            ? displayedInternationalJobs.map((jobContent) => (
                <Grid item key={jobContent.id}>
                  <JobDetailCard
                    key={jobContent.id}
                    jobContent={jobContent}
                    setOpenJobApplicationForm={setOpenJobApplicationForm}
                  />
                </Grid>
              ))
            : displayedNationalJobs.map((jobContent) => (
                <Grid item key={jobContent.id}>
                  <JobDetailCard
                    key={jobContent.id}
                    jobContent={jobContent}
                    setOpenJobApplicationForm={setOpenJobApplicationForm}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>

      {active === "International" ? (
        <Pagination
          count={totalPageInternational}
          page={currentPageInternational}
          onChange={handlePageChangeInternational}
          shape="rounded"
          sx={{
            marginTop: "2rem",
            marginBottom: "2rem",
            "& .MuiPaginationItem-root": {
              color: "#fff",
            },
          }}
        />
      ) : (
        <Pagination
          count={totalPageNational}
          page={currentPageNational}
          onChange={handlePageChangeNational}
          shape="rounded"
          sx={{
            marginTop: "2rem",
            marginBottom: "2rem",
            "& .MuiPaginationItem-root": {
              color: "#fff",
            },
          }}
        />
      )}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>Latest</MenuItem>
        <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
        <MenuItem onClick={handleClose}>All Time</MenuItem>
      </Menu>
    </Box>
  );
};
export default FirstSectionSkilledList;
