import { Box, Typography, Stack } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
function FirstSectionTitle() {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={2}>
          <MailOutlineIcon />
          <Typography>info@apimanpower.com</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <PhoneIcon />
          <Typography>+977-9801299092</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <WhatsAppIcon />
          <Typography>+977-9851169909</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FirstSectionTitle;
