import { Box } from "@mui/material";
import ContactMap from "./contact_map";
import ContactForm from "./contact_form";
function SecondSectionContactForm() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "3rem",
        px: { xs: "0", lg: "2rem" },
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <ContactMap />
      <ContactForm />
    </Box>
  );
}

export default SecondSectionContactForm;
