import React from "react";
import { Container, Box } from "@mui/material";
import FirstSectionWorkerApplication from "./components/first_section_w_application";
function WorkerApplicationView() {
  return (
    <main>
      <Box
        component="section"
        sx={{
          marginTop: "6rem",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 3, lg: 4 },
          }}
        >
          <FirstSectionWorkerApplication />
        </Container>
      </Box>
    </main>
  );
}

export default WorkerApplicationView;
