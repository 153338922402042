import React from "react";
import { Box, Typography } from "@mui/material";
function VideoSection() {
  return (
    <Box
      sx={{
        width: "100%",
        background: "#D9D9D9",
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>Video Section</Typography>
    </Box>
  );
}

export default VideoSection;
