import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { ExitToApp, Email } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
// REDUX IMPORTS
import { logout as customLogout } from "../../redux/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
//IMPORT UTILS
import { stringAvatar } from "../../utils/formatName";

function AccountPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const role = useSelector((state) => state.auth.role);
  const { logout, isAuthenticated } = useAuth0();
  function handleLogout() {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      dispatch(customLogout());
      navigate("/");
    }
  }
  function goToWorkerDashboard() {
    navigate("/worker");
  }
  function goToEmployerDashboard() {
    navigate("/employer");
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "space-evenly",

        width: "16rem",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ background: "rgb(230, 231, 232)", padding: "0.6rem" }}
      >
        <Avatar
          {...stringAvatar("Employer Name")}
          // src="/path/to/avatar.jpg"
          // sx={{ width: 65, height: 65 }}
          style={{ width: "65px", height: "65px" }}
        />
        <Box>
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            User name
          </Typography>
          {/* <Typography
            variant="body1"
            sx={{
              fontSize: "9px",
              fontWeight: "bold",
              border: "2px solid #272361",
              width: "fit-content",
              padding: "2px",
              borderRadius: "2px",
              color: "#272361",
            }}
          >
            Worker
          </Typography> */}
          <Stack direction="row" spacing={0.2}>
            <Email fontSize="string" />
            <Typography sx={{ fontSize: "12px" }}>
              johnnydoe@gmail.com
            </Typography>
          </Stack>
        </Box>
      </Stack>

      <List>
        {/* <ListItem onClick={() => console.log("Go to dashboard")}>
          <ListItemText primary="Go to Dashbard" />
        </ListItem> */}
        {/* <Divider /> */}

        {role === "worker" && !pathname.startsWith("/worker") && (
          <>
            <ListItem onClick={goToWorkerDashboard} sx={{ cursor: "pointer" }}>
              <ListItemText primary="Go to Dashboard" />
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
            <Divider />
          </>
        )}
        {role === "employer" && !pathname.startsWith("/employer") && (
          <>
            <ListItem
              onClick={goToEmployerDashboard}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText primary="Go to Dashboard" />
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
            <Divider />
          </>
        )}

        <ListItem onClick={handleLogout} sx={{ cursor: "pointer" }}>
          <ListItemText primary="Logout" />
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
        </ListItem>
      </List>
    </Box>
  );
}

export default AccountPopover;
