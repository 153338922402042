import React from 'react';
import slideContent from './sliderContent';
import './slider.styles.scss';
const Slide = (props) =>{
    return(
        <section className="slides">
            {slideContent.map((slide, index) => <div
                key={index} 
                className={index === props.activeIndex ? "slides active" : "inactive"} 
            >
                <div style={{
                    height: "100%",
                    width: "100%"
                }}>
                    <img className='slide-image' src={slide.urls} alt="slides" />
                </div>
            </div>)}
        </section>
    );
}
export default Slide;