import React from "react";
// import StreetMap from "../../images/StreetMap.png";
function ContactMap() {
  return (
    <div className="contact__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d264.98812134625837!2d85.31143910519522!3d27.666059641006036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb182cedae34b9%3A0x68338fec2a096f97!2sRing%20Rd%20Uttar%2C%20Lalitpur%2044600!5e0!3m2!1sen!2snp!4v1688667920693!5m2!1sen!2snp"
        className="contact__map--image"
        title="Street Map"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default ContactMap;
