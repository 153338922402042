import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Stack } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import "./popup_form.styles.scss";
import { closeSignupDialog, openLoginDialog } from "../../redux/dialogSlice";
import { signupUser } from "../../redux/authSlice";

function WorkerSignupForm() {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  function openLoginForm() {
    // setOpenSignupForm(false);
    // setOpenLoginForm(true);
    dispatch(closeSignupDialog());
    dispatch(openLoginDialog());
  }
  function handleSubmit(e) {
    e.preventDefault();
    dispatch(
      signupUser({
        fullName,
        email,
        password,
        confirmPassword,
        selectedForm: "worker",
      })
    );
  }
  return (
    <Box className="popup">
      <Box className="popup__form">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username" style={{ color: "#F15B24" }}>
              <Typography>Full Name</Typography>
            </label>

            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setfullName(e.target.value)}
              placeholder="Full Name"
              className="popup__form--input"
            />
          </div>
          <div>
            <label htmlFor="email_a" style={{ color: "#F15B24" }}>
              <Typography>Email</Typography>
            </label>

            <input
              type="email"
              id="email_a"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              placeholder="Email"
              className="popup__form--input"
            />
          </div>
          <div>
            <label htmlFor="password" style={{ color: "#F15B24" }}>
              <Typography>Password</Typography>
            </label>

            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="popup__form--input"
            />
          </div>
          <div>
            <label htmlFor="confirm_password" style={{ color: "#F15B24" }}>
              <Typography>Confirm Password</Typography>
            </label>

            <input
              type="password"
              id="confirm_password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="popup__form--input"
            />
          </div>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button className="popup__form--button">Signup</button>
          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography
            sx={{ color: "white", fontSize: "14px", textAlign: "center" }}
          >
            Already have an account?{" "}
            <strong
              onClick={openLoginForm}
              style={{ fontSize: "16px", cursor: "pointer" }}
            >
              Login
            </strong>
          </Typography>
          <Stack
            direction="row"
            width={{ xs: "95%", md: "80%" }}
            spacing={2}
            sx={{ border: "2px solid white", borderRadius: "20px" }}
            justifyContent={"center"}
            padding="3px 0px"
            alignItems={"center"}
          >
            <FacebookRoundedIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Facebook
            </Typography>
          </Stack>

          <Stack
            direction="row"
            width={{ xs: "90%", md: "70%" }}
            spacing={2}
            sx={{ border: "2px solid white", borderRadius: "20px" }}
            justifyContent={"center"}
            padding="3px 0px"
            alignItems={"center"}
          >
            <GoogleIcon style={{ fill: "white" }} />
            <Typography sx={{ color: "white", fontSize: "0.8rem" }}>
              Or Login with Google
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default WorkerSignupForm;
