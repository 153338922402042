import React from "react";

import WorkerInstructionView from "src/views/worker_instruction";
import Footer from "../../layouts/footer/footer.components";

function WorkerInstructioPage() {
  return (
    <>
      <WorkerInstructionView />
      <Footer />
    </>
  );
}
export default WorkerInstructioPage;
