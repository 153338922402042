import React from "react";
import { Container, Box } from "@mui/material";
import FirstSectionWorkerHome from "./components/first_section";
import SecondSectionWorkerListing from "./components/second_section_w_listing";
function WorkerHomeView() {
  return (
    <main>
      <Box
        component="section"
        sx={{
          marginTop: "6rem",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disableGutters
        >
          <FirstSectionWorkerHome />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "primary.subColor" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disableGutters
        >
          <SecondSectionWorkerListing />
        </Container>
      </Box>
    </main>
  );
}

export default WorkerHomeView;
