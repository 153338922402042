import React from "react";
import { Box, Typography } from "@mui/material";
function InstructionSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography sx={{ fontSize: "20px" }}>Instruction</Typography>
      <Typography variant="body1">
        1. After login you will have a list of job
      </Typography>
      <Typography variant="body1">
        2.Select your job and click on apply.
      </Typography>
      <Typography variant="body1">3. You see the application form.</Typography>
      <Typography variant="body1">
        4. Fill up each and every document there and click submit.
      </Typography>
      <Typography variant="body1">
        5. After submission of form you can check/update your application
        through “Application” Checking/updating of application allowed only
        before admin verification
      </Typography>
      <Typography variant="body1">
        6. Similarly notification, update and progress of your application
        delivered on “Progress”
      </Typography>
      <Typography variant="body1">
        7. You must be up to date through “Progress”
      </Typography>
    </Box>
  );
}

export default InstructionSection;
