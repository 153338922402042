import React from "react";

import SeeAllUnskilledView from "src/views/see_all_unskilled";
import Footer from "../../layouts/footer/footer.components";

function SkilledPage() {
  return (
    <>
      <SeeAllUnskilledView />
      <Footer />
    </>
  );
}

export default SkilledPage;
