import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import profile from "../../../assets/images/profile/gb-removebg-preview.png";
import AboutDialog from "./aboutDialog";
import certificate_data from "../certificate_data";

function FirstSectionAbout() {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({ title: "", imageUrl: "" });

  const handleClose = () => setOpen(false);

  const handleAboutDialog = (data) => {
    setSelectedData({
      title: data.title,
      imageUrl: data.doc,
    });
    setOpen(true);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={"3rem"}
    >
      {/* IMAGES */}
      <Box
        sx={{
          width: { xs: "auto", md: "100%" },
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "3rem", md: "1rem" },
          margin: "auto",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              height: "14rem",
              width: "240px",
              backgroundImage: `url(${profile})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />

          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "550",
                textAlign: "center",
                fontFamily: "Roboto-Serif",
                mt: 1,
                fontSize: { xs: "18px", sm: "20px", lg: "24px" },
              }}
            >
              Gopal Baraudi
            </Typography>

            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: "500",
                textAlign: "center",
                fontFamily: "cursive",
              }}
            >
              (Chairman)
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              marginTop: "1rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "justify",
                fontFamily: "Roboto-Serif",
              }}
            >
              At Api Manpower, we specialize in providing top-tier manpower solutions to
              businesses and industries across the globe. With a commitment to excellence,
              we connect skilled professionals with international companies, ensuring the
              right talent meets the right opportunity.

              Our expertise spans various sectors, including construction, hospitality,
              healthcare, manufacturing, and more. We understand the complexities of
              global recruitment and work closely with both clients and candidates to
              create a seamless hiring process.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* CERTIFICATES */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: { xs: "20px", sm: "22px", md: "28px", xl: "40px" },
            fontFamily: "Roboto-Serif",
            mb: { xs: 1, sm: 2, md: 3, lg: 4 },
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Company Documents
        </Typography>

        <Box
          sx={{
            width: { xs: "100%", md: "60%", lg: "40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1.5rem",
            flexDirection: "column",
            marginBottom: "3rem",
          }}
        >
          {certificate_data.map((data, index) => (
            <Box
              key={index}
              className="progress__bar progress__bar--completed"
              style={{
                borderRadius: "20px",
                cursor: "pointer",
                border: "1px solid #ddd",
                padding: "0.5rem",
                textAlign: "center",
                transition: "0.3s ease",
                display:"flex",
                justifyContent:"center"
              }}
              onClick={() => handleAboutDialog(data)}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: { xs: "14px", sm: "16px",md:"20px" },
                  fontFamily:"Roboto-Serif"
                  // border:"1px solid red"
                }}
              >
                {data.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* SINGLE DIALOG OUTSIDE THE LOOP */}
      <AboutDialog
        open={open}
        onClose={handleClose}
        title={selectedData.title}
        imageUrl={selectedData.imageUrl}
      />
    </Box>
  );
}

export default FirstSectionAbout;
