import React from "react";
import { Helmet } from "react-helmet-async";

import Footer from "../../layouts/footer/footer.components";
import EmployerProgressView from "src/views/employer_progress";

function EmployerProgressPage() {
  return (
    <>
      <Helmet>
        <title>Employer | API</title>
        <meta name="description" content="Api Progress Page" />
      </Helmet>
      <EmployerProgressView />
      <Footer />
    </>
  );
}

export default EmployerProgressPage;
