import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";

const ImageDialog = ({ open, onClose, title, imageUrl }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Title */}
      <DialogTitle sx={{ fontWeight: "bold", textAlign: "center",textTransform:"capitalize",fontFamily:"Roboto-Serif"}}>
        {title}
      </DialogTitle>

      {/* Content with Image */}
      <DialogContent>
        <Box
          component="img"
          src={imageUrl}
          alt={title}
          sx={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            borderRadius: 2,
          }}
        />
      </DialogContent>

      {/* Actions */}
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
