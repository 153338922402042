import React from "react";
import { Container, Box } from "@mui/material";
import FirstSectionSkilledList from "./components/first_section_skilled_list";

function SeeAllSkilledView() {
  return (
    <main>
      <Box component="section">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disableGutters
        >
          <FirstSectionSkilledList />
        </Container>
      </Box>
    </main>
  );
}

export default SeeAllSkilledView;
